.block-highlight {
	@include flex(center, center, column);

	&.is_main_section {

		@media screen and ( min-width: $DESK ) {
			@include is_main_section;
		}
		
	}
	&.is-single {

		@media screen and ( min-width: $DESK ) {
			.section__inner {
				margin-top: -500px;
			}
		}
		
	}

	&--default {
		padding: 120px 30px;


		// .grid {
		// 	@include flex( center, center, row);
		// }

		.block-highlight__headline {
			color: $BLUE;
			font-family: $H2_FONT;
			font-size: $H2_SIZE;
			margin-bottom: 30px;

			@media screen and ( max-width: $LAP_MAX ) {
				padding: 0 30px;
			}
		}
		.block-highlight__content {
			
			p {
				color: $DARK_GREY;
				font-family: $P_FONT;
				font-size: $P_SIZE;
				line-height: 36px;
			}

			a {
				color: $BLUE;
				font-family: $A_FONT;
				font-size: $A_SIZE;
				opacity: 1;
				transition: all .3s;

				&:hover {
					opacity: .7;
				}				
			}

			@media screen and ( max-width: $LAP_MAX ) {
				padding: 0 30px;
			}

		}
	}

	@media screen and ( max-width: $LAP_MAX ) {
		padding: 30px 0 0;

		.block-highlight__main-image {
			margin-bottom: -18px;
			margin-top: 10px;
			width: 100%;
		}
	}
}

.block-highlight__main-image {
	height: auto;
	max-width: 100%;
	width: 100%;

	@media screen and ( max-width: $LAP_MAX ) {

	}
}

// Highlight_2
#highlight_2 {
	background-color: $OFF_WHITE;

	.block-highlight__main-image {
		width: 90%;
		width: calc(100% - 60px);

		@media screen and ( max-width: $LAP_MAX ) {
			width: 100%;
		}
	}
}

.block-highlight__content {
	.section--left & {
		@media screen and ( min-width: $LAP_MIN ) {
			width: 90%;
			width: calc(100% - 60px);
		}		
	}

}

.block-highlight__subtext{
	font-size: 18px;
	color: $GREY;
	line-height: 24px;
	max-width: 425px;

	@media screen and ( max-width: $LAP_MAX ) {
		padding: 30px 30px 0;
	}
	
	strong {
		font-size: inherit;
		color: $BLUE;
	}
	a {
		color: inherit;
	}
}
.block-highlight__testimonial_content {
	display: block;
	margin-bottom: 15px;
}
.block-highlight__testimonial_image {
	border-radius: 100px;
	margin: 0 !important;
	width: 100% !important;
}
.block-highlight__testimonial_name{
	color: $BLUE;
	font-weight: 500;
}
.block-highlight__testimonial_meta {
	padding-left: 15px;

	> span {
		display: block;
	}
	strong {
		color: $BLUE;
	}
}
.block-highlight__related-article-link {
	transition: color .3s;

	&:hover {
		color: $DARK_GREY;
	}
}
.block-highlight__patient-download-link {
	align-items: center;
	color: $GREY;
	display: flex;
	font-size: 17px;
	margin: 30px 0 0;
	transition: color .3s;

	&:hover {
		color: $DARK_GREY;
	}
}
.block-highlight__divider {
	display: none;

	@media screen and ( min-width: $LAP_MAX + 1 ) {
		border-top: 1px solid $LIGHT_GREY;
		display: block;
		margin: 30px 0;
		opacity: 1;
		width: 150px;
	}
}
.block-masthead__pdf-icon {
	margin-right: 15px;
	width: 28px;
}

