@mixin flex($align: center, $justify: space-between, $direction: row){
    align-items: $align;
    display: flex;
    flex-wrap: wrap;
    justify-content: $justify;
    flex-direction: $direction;
}

@mixin is_main_section(){
	padding-bottom: 0;
    background-image: linear-gradient(#fafafa, #fff 38%);

    .section__inner {
		padding: 70px 0;
		margin-top: -200px;
		background-color: #fff;
		max-width: calc(1400px + 30px);
    }
}