// COLORS
$WHITE: #fff;
$OFF_WHITE: #fafafa;
$BG_COLOR: $WHITE;
$TEAL: #37dcd4;
$BLUE: #2aaedc;
$DARK_BLUE: #1c88b4;

$GREY: #aaa;
$DARK_GREY: #777;
$LIGHT_GREY: #ddd;

// TYPE
$SAN-SERIF: 'futura-pt';
$SERIF: 'mrs-eaves';

$H1_FONT: 'mrs-eaves-roman-small-caps';
$H1_SIZE: 65px;

$H2_FONT: 'mrs-eaves-roman-small-caps';
$H2_SIZE: 26px;

$H3_FONT: 'mrs-eaves-roman-small-caps';
$H3_SIZE: 18px;

$HIGHLIGHT_HEADLINE_FONT: 'futura-pt';
$HIGHLIGHT_HEADLINE_SIZE: 35px;

$P_FONT: 'futura-pt';
$P_SIZE: 22px;

$LI_FONT: 'futura-pt';
$LI_SIZE: 22px;

$A_FONT: 'mrs-eaves';
$A_SIZE: 22px;

// LAYOUT
$CONTAINER: 1400px;
$SITE_WIDTH: 1800px;
$SITE_PADDING: 30px;

// BREAKPOINTS
$PALM: 700px;
$LAP_MIN: 701px;
$LAP_MAX: 1023px;
$PORTABLE: 1023px;
$DESK: 1024px;
$LG_DESK: 1600px;