.block-casestudies {
	@include flex(center, center, column);

	padding: 140px 0;

	@media screen and ( max-width: $LAP_MAX ) {
		padding: 70px 30px;
	}
}
.block-casestudies__heading {
	color: $GREY;
	font-family: $HIGHLIGHT_HEADLINE_FONT;
	font-size: $HIGHLIGHT_HEADLINE_SIZE;
	letter-spacing: .04em;
	margin-bottom: 80px;
	text-align: center;
	text-transform: uppercase;
	// 
	@media screen and ( max-width: $LAP_MAX ) {
		text-align: left;
		margin-bottom: 40px;
	}
}
.block-casestudies__item {

	.block-casestudies__content {
		margin-bottom: 60px;
	}
	
	&:last-child {
		.block-casestudies__content {
			margin-bottom: 0;
		}
	}

	@media screen and ( max-width: $LAP_MAX ) {
		// padding: 0 30px;
	}
	
}
.block-casestudies__title {
	color: $BLUE;
	font-family: $H3_FONT;
	font-size: $H3_SIZE;
	letter-spacing: .04em;
	line-height: 24px;
	margin-bottom: 30px;
	text-transform: uppercase;
}
.block-casestudies__label {
	@include flex(center, flex-start);

	color: $GREY;
	font-family: $SERIF;
	font-size: 16px;
	letter-spacing: .2em;
	line-height: 24px;
	text-align: right;
	text-transform: uppercase;

	@media screen and ( max-width: $LAP_MAX ) {
		align-items: flex-end;
		text-align: left;
	}
	
}
.block-casestudies__icon {
	margin-right: 10px;
	position: relative;
	top: -4px;
	width: 13px;

	@media screen and ( min-width: $LAP_MAX ) {
		top: -2px;
	}

}
.block-casestudies__copy {
	color: $DARK_GREY;
	font-family: $SAN-SERIF;
	font-size: 18px;
	letter-spacing: .03em;
	line-height: 36px;
	text-shadow: 0 0 0 $DARK_GREY;
}
.block-casestudies__button {
	border-radius: 100px;
	border: 1px solid $GREY;
	color: $GREY;
	display: block;
	font-family: $P_FONT;
	font-size: 18px;
	letter-spacing: .03em;
	margin: 30px 0 0;
	padding: 15px 0;
	text-align: center;
	text-decoration: none;
	width: 182px;
	transition: all .3s;

	&:hover {
		background-color: $DARK_GREY;
		border-color: $DARK_GREY;
		color: $WHITE;
	}	
}