// fade in typekit async text to hide flicker
h1,h2,h3,h4 {
		opacity: 0;
		transition: opacity .7s;

		&.fade-in {
			opacity: 1;
		}
}
p:not(.nf-response-msg p),li,span {
		opacity: 0;
		transition: opacity .7s .2s;

		&.fade-in {
			opacity: 1;
		}
}
a:not(.block-header__sitelink) {
		opacity: 0;
		transition: opacity .5s .4s;

		&.fade-in {
			opacity: 1;
		}
}
a.block-header__sitelink {
		opacity: 0;
		transition: opacity .2s;

		&.fade-in {
			opacity: 1;
		}
}
body {
	background-color: $BG_COLOR;
	font-family: Helvetica, sans-serif;
	font-family: $P_FONT;
	font-weight: 300;
	font-size: 16px;
	letter-spacing: .05em;
}
img {
	max-width: 100%;
	height: auto;
}
strong {
	font-weight: 400;
}
.grid{
	&__item {
		// background-color: #eee;
		margin: 15px 0;
		// padding: 30px;
	}
}
#wrapper {
	// @include flex(stretch, space-between, column);
	@media screen and ( max-width: $LAP_MAX ) {
		overflow: hidden;
	}
	
}
.section {
	&--accent {
		background-color: $OFF-WHITE;
	}
	&__inner {
		max-width: 1400px;
		width: 100%;
	}
}


/* ==================== 
---- Contact Form ------
====================== */

.nf-response-msg p {
	line-height: 30px;
}

/* ==================== 
---- Utilities ------
====================== */

.is-invisible {
	visibility: hidden !important;
}
.is-off-white {
	background-color: $OFF-WHITE;
}