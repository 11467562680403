#block-about {
	@include flex(center, center, column);

	border-top: 10px solid $WHITE;
	color: $WHITE;
}

#block-about__inner {
	background-color: $DARK_BLUE;
	max-width: $SITE_WIDTH;
	padding: 15px 0;
	// padding: 15px $SITE_PADDING;
	// padding: 15px 0;
}
#block-about__content {

	@media screen and ( min-width: $LAP_MAX + 1 ) {
		padding-left: 30px;
	}
	
	p {
		font-size: 18px;
		line-height: 38px;
	}

	a {
		color: $TEAL;
		font-family: $A_FONT;
		font-size: 20px;
		opacity: 1;
		transition: all .3s;

		&:hover {
			opacity: .7;
		}
	}

	@media screen and ( max-width: $LAP_MAX ) {	
		padding: 0 10%;
	    text-align: center;
	}

}

.block-about__main-image {
	border-radius: 500px;
	border: 3px solid $WHITE;
	height: auto;
	max-width: $CONTAINER;
	width: 250px;

	@media screen and ( min-width: $LAP_MIN + 1 ) {
		max-width: 100%;
	}	
	@media screen and ( max-width: $LAP_MAX ) {
		display: block;
		margin: 0 auto;
	}
}

#about_1 {
	p {
		text-align: justify;
	}
}
#about_3 {
	background-color: $OFF_WHITE;

	.block-highlight__main-image {
		width: 90%;
		width: calc(100% - 60px);

		@media screen and ( max-width: $LAP_MAX ) {
			width: 100%;
		}
	}
}