.block-footer {
	@include flex(center, center, column);
	min-height: 200px;
	padding: 100px 30px;

	@media screen and ( max-width: $LAP_MAX ) {
		padding: 70px 30px;
	}
	
}
.block-footer__heading {
	color: $DARK_GREY;
	font-family: $SAN-SERIF;
	font-size: $H3_SIZE;
	letter-spacing: .04em;
	margin-bottom: 20px;
	text-align: left;
	text-transform: uppercase;
}
.block-footer__locations {
	margin-top: 24px;

	.grid__item {
		margin: 0;
	}
	.block-footer__divider {
		@media screen and ( max-width: $LAP_MAX ) {
			margin-top: 15px;
		}
	}
}
.block-footer__phone {
	// margin-top: 30px;

	a {
		color: inherit;
		text-decoration: none;
	}
}
.block-footer__email {
	@media screen and ( max-width: $LAP_MAX ) {
		display: none;
	}
	
}
.block-footer__city {
	line-height: 1.5;
}
.block-footer__city {
	color: $BLUE;
	font-family: $SERIF;
	font-size: 20px;
	letter-spacing: .04em;
	line-height: 24px;
	margin-bottom: 15px;
}
.block-footer__copy {
	color: $DARK_GREY;
	font-family: $SAN-SERIF;
	font-size: 16px;
	letter-spacing: .03em;
	line-height: 32px;
	margin-bottom: 30px;
	text-shadow: 0 0 0 $DARK_GREY;

	strong {
		font-weight: 800;
	}

	@media screen and ( max-width: $LAP_MAX ) {
		font-size: 20px;
		line-height: 30px;
	}
	
}
.block-footer__contact {	
	font-family: $SAN-SERIF;

	@media screen and ( min-width: $DESK ) {
		max-width: 100%;
		padding-right: 30px;
	}

	#ninja_forms_required_items, .nf-field-container {
		font-family: inherit;
		margin: 10px 0;
	}
	.nf-form-fields-required {
		color: $GREY;
		font-family: inherit;
	}
}
.block-footer__input, .block-footer__message {
	border: 1px solid $GREY !important;
	padding: 15px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 100;
}
.block-footer__button {
	background-color: $BLUE;
	border: none;
	color: $WHITE;
	cursor: pointer;
	font-family: inherit;
	padding: 15px 30px;
    font-size: 18px;
    font-weight: 100;
    max-width: 200px;
    text-align: center;
    text-transform: uppercase;
	transition: background-color .3s;

	&:hover {
		background-color: $TEAL;
	}    
}
.block-footer__map {
	max-width: 100%;

	@media screen and ( max-width: $LAP_MAX ) {
		margin-bottom: 15px;
	}
}
.block-footer__copyright {
	color: $GREY;

	@media screen and ( max-width: $LAP_MAX ) {
		font-size: 12px;
	}
	
}
.block-footer__menu-items {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    @media screen and ( max-width: $LAP_MAX ) {
    	justify-content: flex-start;
    }

    @media screen and ( max-width: 350px ) {
    	align-items: flex-start;
    	flex-direction: column;
    }
    

    li {
    	color: $GREY;
    	margin: 0 0 0 15px;

    	@media screen and ( max-width: $LAP_MAX ) {
    		margin: 0 15px 0 0;
    	}
    	
    }
    a {
    	color: inherit;
    	text-decoration: none;
    	text-transform: lowercase;

    	&:hover {
    		opacity: .8;
    	}
    }
}
.block-footer__divider {
  border-bottom: 1px solid $LIGHT_GREY;
  height: 0;
  margin-bottom: 20px;
  width: 100%;
}