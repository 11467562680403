.component-subpage-header {
	@include flex(center, center, column);
	background-color: $TEAL;
	background-image: linear-gradient(90deg, transparent 15%, $BLUE 50%);
	color: $WHITE;
	// max-height: 233px;
	// layout test
	max-height: none;
	overflow: hidden;

	@media screen and ( min-width: $LAP_MAX ) {
		padding-left: 30px;
	}

	&.is-single {
		@media screen and ( min-width: $DESK ) {
			min-height: 500px;
		}
	}
	
	
}

.component-subpage-header__inner {
	// background-color: $TEAL;
	// background-image: linear-gradient(90deg, transparent, $BLUE);
	// max-width: $SITE_WIDTH;
	background-size: cover;
    background-position: center 40%;

	@media screen and ( min-width: $DESK ) {
		background-image: none !important;
	}

	> .grid {
		@include flex();

		.grid__item {
			margin: 0 0 -2px;

			@media screen and ( max-width: $LAP_MAX ) {
				padding: 60px 30px;
				&:last-child {
					display: none;
				}
			}			
		}
	}
}
.component-subpage-header__content {
	@include flex(flex-start, center, column);

	@media screen and ( max-width: $LAP_MAX ) {
		align-items: flex-start;
	}	
	
}
.component-subpage-header__headline {
	color: inherit;
	font-family: $H1_FONT;
	font-SIZE: 50px;
	line-height: 40px;
	// margin-bottom: 20px;
	text-align: left;
	// max-width: 500px;

	// layout test
	@media screen and ( min-width: $DESK ) {
	    margin-bottom: 80px;
	    text-align: left;
	    min-height: 300px;
	    display: flex;
	    align-items: center;
	}

	@media screen and ( max-width: $PALM ) {
		font-size: 34px; 
		margin-bottom: 15px;
	}
	
}
.component-subpage-header__calltoaction {
	border-radius: 100px;
	border: 2px solid $WHITE;
	color: inherit;
	font-family: $P_FONT;
	font-SIZE: $P_SIZE;
	margin: 30px 0;
	padding: 15px 30px;
	text-decoration: none;
}
.component-subpage-header__subtext {
	color: inherit;
	font-family: $P_FONT;
	font-SIZE: $P_SIZE;
}
.component-subpage-header__divider {
	border-color: $WHITE;
	border-width: 0 0 1px;
	margin: 0;
	opacity: .5;
	width: 100px;
}
.component-subpage-header__main-image {
	height: auto;
	max-width: $CONTAINER;
	width: 100%;
}