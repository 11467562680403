.block-resources {
	@include flex(center, center, column);

	background-color: $TEAL;
	background-image: linear-gradient(90deg, transparent, $BLUE);
	color: $WHITE;
	padding: 140px 0;

	@media screen and ( max-width: $LAP_MAX ) {
		padding: 70px 30px;
	}
	
}
.block-resources__heading {
	color: $WHITE;
	font-family: $HIGHLIGHT_HEADLINE_FONT;
	font-size: $HIGHLIGHT_HEADLINE_SIZE;
	letter-spacing: .04em;
	margin-bottom: 80px;
	text-align: center;
	text-transform: uppercase;

	@media screen and ( max-width: $LAP_MAX ) {
		text-align: left;
		margin-bottom: 40px;
	}
	
}
.block-resources__label {
	color: $WHITE;
	display: block;
	font-family: $SERIF;
	font-size: 16px;
	letter-spacing: .2em;
	line-height: 24px;
	margin-bottom: 40px;
	text-transform: uppercase;
}
.block-resources__item{
	margin-bottom: 40px;

	@media screen and ( max-width: $LAP_MAX ) {
		margin-bottom: 30px;
	}
	
}
.block-resources__title {
	align-items: center;
	color: $WHITE;
	display: flex;
	font-family: $SAN-SERIF;
	font-size: 18px;
	letter-spacing: .1em;
	line-height: 1;
	margin-bottom: 10px;
}
.block-resources__author {
	color: $WHITE;
	display: block;
	font-family: $SAN-SERIF;
	font-size: 16px;
	font-style: italic;
	letter-spacing: .1em;
	line-height: 1;
	margin-left: 22px;
}
.block-resources__link {
	align-items: center;
	color: $WHITE;
	display: flex;
	font-family: $SAN-SERIF;
	font-size: 18px;
	letter-spacing: .1em;
	line-height: 1;
	text-decoration: none;

	&:hover {
		opacity: .7;
	}
}
.block-resources__book-icon {
	margin-right: 10px;
	width: 13px;
}
.block-resources__link-icon {
	margin-right: 10px;
	width: 13px;
}
.block-resources__divider {
  border-bottom: 1px solid rgba(255,255,255,.3);
  height: 0;
  margin-bottom: 40px;
  width: 170px;
}