#block-masthead {
	@include flex(center, center, column);
	color: $WHITE;
}

#block-masthead__inner {
	background-color: $TEAL;
	background-image: linear-gradient(90deg, transparent 15%, $BLUE 50%);
	max-width: $SITE_WIDTH;

	> .grid {
		@include flex($align: stretch);

		.grid__item {
			margin: 0 0 0;

			&:last-child {
				background-size: cover;
				background-position: center;

				@media screen and ( max-width: $PALM ) {
					display: none;
				}
			}
		}
	}

	img {
		@media screen and ( min-width: 1023px ) and (max-width: 1045px) {
			max-width: 103%;
			// max-width: calc(100% + 5px;);
		}
	}
}
.block-masthead__content {
	@include flex(center, center, column);
	
	background-size: cover;
	padding: 90px 0;

	@media screen and ( min-width: $PALM ) {
		background-image: none !important;
	}

	@media screen and ( min-width: $PALM ) and ( max-width: $LAP_MAX - 1 ) {
		padding: 60px 0;
	}	

	@media screen and ( min-width: $LG_DESK ) {
		padding: 120px 0;
	}
}
.block-masthead__headline {
	color: inherit;
	font-family: $H1_FONT;
	font-size: 52px;
	letter-spacing: .04em;
	line-height: 46px;
	margin-bottom: 0;
	text-align: center;
	max-width: 400px;

	@media screen and ( min-width: $PALM ) {
		font-size: 42px;
		line-height: 36px;
		margin-bottom: 15px;
	}

	@media screen and ( min-width: $LAP_MAX ) {
		font-size: $H1_SIZE;
		line-height: 60px;
		margin-bottom: 45px;
	}
}
.block-masthead__calltoaction {
	border-radius: 100px;
	border: 2px solid $WHITE;
	color: inherit;
	font-family: $P_FONT;
	font-weight: 300;
	font-size: 26px;
	margin: 45px 0 30px;
	padding: 15px 45px 20px;
	text-decoration: none;
	transition: all .3s;

	&:hover {
		background-color: $WHITE;
		color: $TEAL;
	}

	@media screen and ( min-width: $PALM ) and ( max-width: $LAP_MAX - 1 ) {
		font-size: 18px;
		margin: 15px 0 20px;
	}	

	@media screen and ( max-width: 350px ) {
		font-size: 20px;
		padding: 15px 30px 20px;
	}
}
.block-masthead__subtext {
	color: inherit;
	font-family: $P_FONT;
	font-size: 26px;

	a {
		color: inherit;
		text-decoration: none;
	}
}
.block-masthead__divider {
	display: none;

	@media screen and ( min-width: $PALM ) {
		
		display: none;

	}
	@media screen and ( min-width: $LAP_MAX + 1 ) {
		border-color: $WHITE;
		border-width: 0 0 1px;
		display: block;
		margin: 0;
		opacity: .5;
		width: 250px;
	}
}
.block-masthead__main-image {
	// height: auto;
	// max-width: $CONTAINER;
	// width: 100%;
}
.block-masthead__do-symbol {
	margin-top: 45px;
	width: 50px;

	@media screen and ( min-width: $PALM ) and ( max-width: $LAP_MAX - 1 ) {
		margin-top: 25px;
		width: 25px;
	}

}
